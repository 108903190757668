.startseite {
    font-family: 'Neue Haas Unica W1G', sans-serif;

    &__kreis {
        display: block;
        text-decoration: none;
        color: #000;

        .body[data-menue-aktiv="true"] & {
            display: none;
        }
    }

    &__startseite {

        &[data-status="inaktiv"] {
            display: none;
        }

        &[data-status="aktiv"] {
            display: block;
        }
    }

    &__bild {

        .startseite__zeit[data-text-color="schwarz"]+&::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 25%);
            pointer-events: none;
        }
    }

    &__zeit {
        z-index: 10;

        &[data-text-color="weiss"] {
            color: #ffff;
        }

        &[data-text-color="schwarz"] {
            color: #000;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .startseite {
        @include abstand($layout, margin, alle, 0, -30, 0, -30);
        height: calc(var(--vh, 1vh) * 100 - groesse($layout, 120));
        position: relative;

        &__kreis {
            @include abstand($layout, padding, alle, 0, 30, 0, 30);
            @include schriftart($layout, 16, 18, 300);
            background: #F5FF00;
            border-radius: 50%;
            /* Korrekt */
            top: groesse($layout, 20);
            display: inline-block;
            right: groesse($layout, 20);
            position: absolute;
            text-align: center;
            transform: rotate(-20deg);
            width: groesse($layout, 200);
            z-index: 1000;
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: groesse($layout, 200);
            overflow: hidden;

            &:before,
            &:after {
                content: '';
                display: block;
                padding: 25%;
            }
        }

        &__kreis-text {
            display: inline-block;

            &--titel {
                @include abstand($layout, margin, alle, 5, -30, 24, -30);
                @include schriftart($layout, 25, 30, 700);
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: groesse($layout, -15);
                    left: 0;
                    width: 100%;
                    height: groesse($layout, 5);
                    background-color: black;
                }
            }

            &--aktion {
                @include schriftart($layout, 16, 18, 700);
                color: #000;
                text-decoration: none;
            }
        }

        &__bild {
            position: absolute;
            bottom: 0;
            left: 0;
            height: calc(var(--vh, 1vh) * 100 - groesse($layout, 250));
            width: 100%;

            .startseite__zeit[data-text-color="schwarz"] + &::after {
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%);
            }
        }

        &__zeit {
            bottom: groesse($layout, 47);
            position: absolute;
            left: groesse($layout, 30);

            &--zeit {
                @include schriftart($layout, 65, 65, 300);
            }

            &--text {
                @include schriftart($layout, 36, 40, 300);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .startseite {
        padding-left: groesse($layout, 170);
        position: relative;

        &__kreis {
            @include abstand($layout, padding, alle, 0, 35, 0, 35);
            @include schriftart($layout, 22, 23, 300);
            background: #F5FF00;
            border-radius: 50%;
            bottom: groesse($layout, 280);
            display: inline-block;
            left: 0;
            position: absolute;
            text-align: center;
            transform: rotate(-20deg);
            width: groesse($layout, 250);
            z-index: 1000;
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: groesse($layout, 250);
            overflow: hidden;

            &:before,
            &:after {
                content: '';
                display: block;
                padding: 25%;
            }
        }

        &__kreis-text {
            display: inline-block;

            &--titel {
                @include abstand($layout, margin, alle, 5, -35, 30, -35);
                @include schriftart($layout, 32, 38, 700);
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: groesse($layout, -15);
                    left: 0;
                    width: 100%;
                    height: groesse($layout, 5);
                    background-color: black;
                }
            }

            &--aktion {
                @include schriftart($layout, 21, 23, 700);
                color: #000;
                text-decoration: none;
            }
        }


        &__bild {
            height: 100vh;
            width: groesse($layout, 1454);

            &::after {
                width: groesse($layout, 1454);
            }
        }

        &__zeit {
            bottom: groesse($layout, 47);
            position: absolute;
            left: groesse($layout, 252);

            &--zeit {
                @include schriftart($layout, 120, 120, 300);
            }

            &--text {
                @include schriftart($layout, 65, 65, 300);
            }
        }
    }
}