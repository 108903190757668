.login-entry {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    background-color: map-get($farben, hintergrundDunkel);
    color: #fff;
    min-height: 100vh;

    a {
        text-decoration: underline;
        color: #fff;
    }

    &__buch-uebungen--buch {
        appearance: none;
        background-color: white;
        border: none;
        cursor: pointer;
    }

    a.login-entry__buch-uebungen--buch {
        text-decoration: none;
        color: black;
    }

    &__buch-titel,
    &__buch-kategorie,
    &__buch-kaufdatum,
    &__buch-e-book,
    &__buch-uebungen {

        &--beschriftung {
            font-weight: 700;
        }
    }

    &__introtext {
        strong {
            font-weight: 700;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .login-entry {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__benutzer-buttons {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 18, 18, 300);
            display: flex;
            justify-content: flex-end;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: flex;
            }
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;

            &--desktop {
                display: none;
            }

            &--smartphone {
                display: block;
            }
        }

        &__introtext {
            @include abstand($layout, padding, alle, 22, 0, 38, 0);
            @include schriftart($layout, 18, 23, 300);

            ul {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }
        }

        &__untertitel {
            @include abstand($layout, padding, bottom, 30);
            @include schriftart($layout, 22, 22, 700);
            border-bottom: 1px solid #fff;
        }

        &__benutzer-container {
            @include abstand($layout, padding, top, 20);
        }

        &__benutzer {
            @include schriftart($layout, 18, 32, 300);
        }

        &__produkt-container {
            @include abstand($layout, margin, top, 120);
        }

        &__buch {
            @include abstand($layout, padding, alle, 34, 0, 34, 0);
            border-bottom: 1px solid #fff;
        }

        &__buch-beschriftung {
            display: none;
        }

        &__buch-titel,
        &__buch-kategorie,
        &__buch-kaufdatum,
        &__buch-e-book,
        &__buch-uebungen {

            &--beschriftung {
                @include schriftart($layout, 18, 24);

                &--desktop {
                    display: none;
                }

                &--mobile {
                    display: block;
                }
            }

            &--buch {
                @include abstand($layout, margin, bottom, 20);
                @include schriftart($layout, 18, 24);
            }
        }

        &__buch-container {
            @include schriftart($layout, 18, 18, 700);
            font-weight: 700;
            align-items: center;
            display: flex;
        }

        &__buch-uebungen {

            &--buch {
                @include abstand($layout, padding, alle, 0, 10, 0, 10);
                height: groesse($layout, 30);
                text-align: center;
                display: flex;
                align-items: center;
            }
        }
    }
}


    @include mediaquery(desktop) {

        $layout: desktop;

        .login-entry {
            @include abstand($layout, margin, alle, -38, -50, -145, -50);

            &__container {
                @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
            }

            &__titel {
                @include abstand($layout, padding, bottom, 26);
                @include schriftart($layout, 60, 40, 300);
                border-bottom: groesse($layout, 3) solid white;

                &--desktop {
                    display: block;
                }

                &--smartphone {
                    display: none;
                }
            }

            &__benutzer-container {
                @include schriftart($layout, 22, 22);
                @include abstand($layout, padding, top, 48);
                display: flex;
            }

            &__benutzer {
                margin-right: auto;
            }

            &__benutzer-buttons {
                display: flex;
                justify-content: flex-end;

                &--desktop {
                    display: flex;
                }

                &--mobile {
                    display: none;
                }
            }

            &__buch-uebungen--buch {
                @include abstand($layout, margin, top, -7.5);
                @include abstand($layout, padding, alle, 0, 10, 0, 10);
                align-items: center;
                display: flex;
                font-weight: 700;
                height: groesse($layout, 40);
            }

            &__introtext {
                @include abstand($layout, padding, alle, 48, 0, 84, 0);
                @include schriftart($layout, 22, 28, 300);

                ul {
                    @include abstand($layout, margin, alle, 35, 0, 35, 0);

                    li {
                        @include abstand($layout, padding, left, 13);
                    }
                }
            }

            &__produkt-container {
                @include abstand($layout, padding, alle, 84, 0, 109, 0);
            }

            &__untertitel {
                @include abstand($layout, margin, bottom, 69);
                @include schriftart($layout, 36, 40, 700);
            }

            &__buch-beschriftung-border {
                @include abstand($layout, padding, alle, 0, 0, 12, 0);
                @include schriftart($layout, 18, 18);
                border-bottom: 1px solid #fff;
            }

            &__buch-beschriftung {
                @include abstand($layout, margin, alle, 0, -8, 0, -8);
                display: flex;
            }

            &__benutzer {
                @include schriftart($layout, 22, 28, 300);
            }

            &__buch {
                @include abstand($layout, margin, alle, 0, -8, 0, -8);
                display: flex;

                &--buch-border {
                    @include abstand($layout, padding, bottom, 9);
                    @include schriftart($layout, 22, 60);
                    border-bottom: 1px solid #fff;
                }
            }

            &__buch-titel {
                @include abstand($layout, margin, alle, 0, 8, 0, 8);
                width: groesse($layout, 387);
                font-weight: 300;

                &--beschriftung {
                    font-weight: 700;

                    &--desktop {
                        display: block;
                    }

                    &--mobile {
                        display: none;
                    }
                }
            }

            &__buch-kategorie {
                @include abstand($layout, margin, alle, 0, 8, 0, 8);
                width: groesse($layout, 165);
                font-weight: 300;

                &--beschriftung {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                    font-weight: 700;

                    &--desktop {
                        display: block;
                    }

                    &--mobile {
                        display: none;
                    }
                }
            }

            &__buch-kaufdatum {
                width: groesse($layout, 165);
                font-weight: 300;

                &--buch {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                }

                &--beschriftung {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                    font-weight: 700;

                    &--desktop {
                        display: block;
                    }

                    &--mobile {
                        display: none;
                    }
                }
            }

            &__buch-e-book {
                width: groesse($layout, 274);
                font-weight: 300;

                &--buch {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                }

                &--beschriftung {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                    font-weight: 700;

                    &--desktop {
                        display: block;
                    }

                    &--mobile {
                        display: none;
                    }
                }
            }

            &__buch-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: groesse($layout, 165);
            }

            &__buch-uebungen {

                &--buch {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                }

                &--beschriftung {
                    @include abstand($layout, margin, alle, 0, 8, 0, 8);
                    font-weight: 700;

                    &--desktop {
                        display: block;
                    }

                    &--mobile {
                        display: none;
                    }
                }
            }
        }
    }