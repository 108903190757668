.main {
    position: relative;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .main {
        @include abstand($layout, padding, alle, 120, 0, 183, 0);
        
        .body--startseite &,
        .body--word &,
        .body--word-entry &,
        .body--excel &,
        .body--excel-entry &,
        .body--powerpoint &,
        .body--powerpoint-entry &,
        .body--junior &,
        .body--junior-entry &,
        .body--shorty &,
        .body--shorty-entry & {
            @include abstand($layout, padding, alle, 120, 30, 183, 30);
        }
        .body--startseite & {
            @include abstand($layout, padding, alle, 120, 30, 0, 30);
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .main {
        @include abstand($layout, padding, alle, 38, 145, 146, 555);

        .body--startseite & {
            @include abstand($layout, padding, alle, 0, 30, 0, 555);
        }
    }
}