/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.shop {
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    font-family: 'Neue Haas Unica W1G', sans-serif;
    min-height: 100vh;
    font-weight: 300;

    &__buch-input {
        background-color: #fff;
        border-color: #000;
        border-style: solid;
        border-width: 1px 0 1px 0;
        box-sizing: border-box;
        outline: none;
    }

    &__buch-input-buttons {
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #000;
        box-sizing: border-box;
        outline: none;
        color: #000;
    }

    &__buch-titel,
    &__buch-shorty-titel {
        font-weight: 700;
        color: #000;
        text-decoration: none;
        
        a {
            color: #000;
            text-decoration: none;
        }
    }

    &__buch-warenkorb {
        background-color: #000;
        border: 1px solid #000;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
    }

    &__buch-svg-text {
        font-family: "Neue Haas Unica W1G",
            sans-serif;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .shop {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;
        }

        &__buecher {
            @include abstand($layout, margin, alle, 15, 0, 15, 0);
            display: flex;
            flex-wrap: wrap;
        }

        &__buch {
            @include abstand($layout, margin, alle, 15, 0, 15, 0);
            @include abstand($layout, padding, alle, 15, 20, 15, 20);
            @include schriftart($layout, 18, 23);
            background-color: #fff;
            color: black;
            width: 100%;
            position: relative;

            &--mobile {
                display: flex;
            }

            &--desktop {
                display: none;
            }
        }


        &__buch-container-mobile {
            position: relative;

            &--1 {
                display: flex;
            }

            &--2 {
                margin-left: groesse($layout, 20);
            }
        }

        &__buch-container-desktop {
            display: none;
        }

        &__buch-bild {
            width: groesse($layout, 119);
        }

        &__buch-svg {
            position: absolute;
            width: groesse($layout, 52);
            height: groesse($layout, 52);
            transform: rotate(-20deg);
            top: groesse($layout, 10);
            right: groesse($layout, 10);
        }

        &__buch-input-container {
            @include abstand($layout, margin, top, 10);
            display: flex;
            position: relative;
            height: groesse($layout, 30);
        }

        &__buch-input {
            @include abstand($layout, padding, alle, 0, 7, 0, 7);
            @include schriftart($layout, 17, 17);
            width: calc(85 / 144 * 100%);
        }

        &__buch-input-buttons {
            @include schriftart($layout, 17, 17);
            width: calc(29 / 144 * 100%);
            padding: 0;
        }

        &__buch-warenkorb {
            @include abstand($layout, margin, top, 15);
            @include schriftart($layout, 15, 15);
            width: calc(143 / 144 * 100%);
            height: groesse($layout, 30);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .shop {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;
        }

        &__buecher {
            @include abstand($layout, margin, top, 35);
            display: flex;
            flex-wrap: wrap;
            margin-left: groesse($layout, -24.5);
            margin-right: groesse($layout, -24.5);
        }

        &__buch {
            @include abstand($layout, margin, alle, 20, 0, 20, 0);
            @include abstand($layout, padding, alle, 21, 22, 21, 22);
            @include schriftart($layout, 22, 28);
            background-color: #fff;
            color: black;
            margin-left: groesse($layout, 24.5);
            margin-right: groesse($layout, 24.5);
            width: spalten($layout, 1, 4, 1, 0);

            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
                align-content: space-between;
                position: relative;
            }
        }

        &__buch-container-desktop {

            .shop__buch-sticker {
                @include abstand($layout, margin, bottom, -25);
                display: flex;
                flex-wrap: wrap;
            }

            &--1 {
                display: flex;
                justify-content: space-between;
            }

            &--2 {
                width: groesse($layout, 105);
            }

            &--3 {
                position: absolute;
                bottom: groesse($layout, 20);
            }
        }

        &__buch-container-mobile {
            display: none;
        }

        &__buch-svg {
            width: groesse($layout, 75);
            height: groesse($layout, 75);
            transform: rotate(-20deg);
        }

        &__buch-bild {
            @include abstand($layout, margin, alle, 0, 20, 10, 0);
            width: groesse($layout, 120);
        }

        &__buch-preis {
            @include abstand($layout, margin, bottom, 138);
        }

        &__buch-input-container {
            display: flex;
            position: relative;
            height: groesse($layout, 40);
        }

        &__buch-input {
            @include abstand($layout, padding, alle, 3, 11, 5, 11);
            @include schriftart($layout, 20, 20);
            width: groesse($layout, 145);
        }

        &__buch-input-buttons {
            @include schriftart($layout, 20, 20);
            padding: 0;
            width: groesse($layout, 37);
        }

        &__buch-warenkorb {
            @include abstand($layout, margin, top, 14);
            @include schriftart($layout, 18, 18, 700);
            width: groesse($layout, 221);
            height: groesse($layout, 40);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}