.macro-video {
    position: relative;
    // overflow: hidden, so dass Zoom (transform: scale) möglich ist
    overflow: hidden;
    
    &__iframe,
    & iframe {
        background-color: transparent;
        border: none;
        height: 100%;
        left: 0;
        outline: none;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
