.introtext {
    font-family: 'Neue Haas Unica W1G', sans-serif;

    a {
        text-decoration: underline;
        color: #000;
    }

    *:first-child {
        margin-top: 0;
    }
    
    *:last-child {
        margin-bottom: 0;
    }

    &__beschreibung {
        font-family: 'Neue Haas Unica W1G', sans-serif;
        font-weight: 300;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .introtext {
        @include abstand($layout, margin, bottom, 23);
        @include schriftart($layout, 22, 28, 300);

        p {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
        }
        
        ul {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
            
            li {
                @include abstand($layout, padding, left, 13);
            }
        }
        
        ol {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
            padding-left: groesse($layout, 20);
        }

        &__beschreibung {
            @include schriftart($layout, 18, 23);
            @include abstand($layout, margin, bottom, 23);

            p {
                @include abstand($layout, margin, alle, 23, 0, 23, 0);
            }
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .introtext {
        @include abstand($layout, margin, bottom, 48);
        @include schriftart($layout, 36, 42, 300);
        width: spalten($layout, 3.5, 4, 0, 0.5);

        p {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
        }
        
        ul {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);

            li {
                @include abstand($layout, padding, left, 13);
            }
        }
        
        ol {
            padding-left: groesse($layout, 23);
        }

        &__beschreibung {
            @include abstand($layout, margin, bottom, 48);
            @include schriftart($layout, 22, 28);

            p {
                @include abstand($layout, margin, alle, 28, 0, 28, 0);
            }
        }
    }

}