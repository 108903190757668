.datenschutz-impressum {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    min-height: 100vh;

    &__inhalt {

        a {
            color: white;
        }

        p {
            font-weight: 300;
        }

        strong {
            font-weight: 700;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .datenschutz-impressum {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
            word-wrap: break-word;
        }

        &__titel {
            @include abstand($layout, padding, alle, 0, 0, 10, 0);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;
        }

        &__ueberschrift {
            @include abstand($layout, padding, bottom, 8);
            @include schriftart($layout, 18, 23);
        }

        &__inhalt {
            @include schriftart($layout, 18, 23);
            @include abstand($layout, padding, top, 22);
        }

        &__inhalt {

            p {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
            }

            ul {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
                padding-left: groesse($layout, 20);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .datenschutz-impressum {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;
            width: 100%;
        }

        &__ueberschrift {
            @include schriftart($layout, 22, 28);
        }

        &__inhalt {
            @include abstand($layout, padding, top, 48);
            @include schriftart($layout, 22, 28);
            width: spalten($layout, 2, 4, 0, 0);

            p {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
            }

            ul {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                padding-left: groesse($layout, 23);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }
    }
}