/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.warenkorb-seite {
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    font-family: 'Neue Haas Unica W1G', sans-serif;
    min-height: 100vh;
    
    a {
        color: white;
        text-decoration: underline;
    }

    &__buch-titel,
    &__buch-kategorie,
    &__buch-anzahl,
    &__buch-einzelpreis,
    &__buch-preis {

        &--beschriftung {
            font-weight: 700;
        }
    }

    &__buch-loeschen {
        cursor: pointer;
    }

    &__buch-input-container {
        display: flex;
        position: relative;
    }

    &__buch-input {
        background-color: #fff;
        border-color: #000;
        border-style: solid;
        border-width: 1px 0 1px 0;
        box-sizing: border-box;
        outline: none;
    }

    &__buch-input-buttons {
        background-color: #fff;
        border: 1px solid #000;
        box-sizing: border-box;
        cursor: pointer;
        outline: none;
    }

    &__buch-container-preis-button {
        display: flex;
    }

    &__buch-loeschen {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .warenkorb-seite {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid #fff;
        }

        &__introtext {
            @include abstand($layout, padding, top, 20);
            @include schriftart($layout, 22, 28, 300);
        }

        &__warenkorb-container {
            @include abstand($layout, margin, top, 120);
        }

        &__warenkorb-titel {
            @include schriftart($layout, 22, 40, 700);
            @include abstand($layout, padding, bottom, 10);

            &--warenkorb {
                @include abstand($layout, padding, bottom, 22);
                border-bottom: 1px solid #fff;
            }
        }

        &__buch {
            @include abstand($layout, padding, alle, 34, 0, 34, 0);
            border-bottom: 1px solid #fff;
        }

        &__buch-beschriftung {
            display: none;
        }

        &__buch-titel,
        &__buch-kategorie,
        &__buch-anzahl,
        &__buch-einzelpreis,
        &__buch-loeschen {

            &--beschriftung {
                @include schriftart($layout, 18, 24);

                &--desktop {
                    display: none;
                }

                &--mobile {
                    display: block;
                }
            }

            &--buch {
                @include abstand($layout, margin, bottom, 20);
                @include schriftart($layout, 18, 24);
            }
        }

        &__buch-preis {

            &--beschriftung {
                @include schriftart($layout, 18, 24);

                &--desktop {
                    display: none;
                }

                &--mobile {
                    display: block;
                }
            }

            &--buch {
                @include schriftart($layout, 18, 24);
            }
        }

        &__buch-anzahl {

            &--buch {
                @include abstand($layout, margin, top, 7);
            }
        }

        &__buch-input-container {
            height: groesse($layout, 39);
        }

        &__buch-input {
            @include abstand($layout, padding, alle, 0, 11, 0, 11);
            @include schriftart($layout, 22, 22);
            text-align: center;
            width: groesse($layout, 81);
        }

        &__buch-input-buttons {
            @include schriftart($layout, 22, 22);
            padding: 0;
            width: groesse($layout, 35);
        }

        &__buch-container-preis-button {
            @include abstand($layout, margin, bottom, 20);
            justify-content: space-between;
        }

        &__buch-loeschen {
            
            &--desktop {
                display: none;
            }
            
            &--mobile {
                display: flex;
                align-items: flex-end;
            }
        }

        &__buch-loeschen-button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 18, 28, 700);
            background-color: #fff;
            color: #000;
            border: none;
            cursor: pointer;
            outline: inherit;
            height: groesse($layout, 30);
            align-items: center;
            display: flex;
        }

        &__total-container {
            @include abstand($layout, margin, bottom, 109);
            display: flex;
        }

        &__total {
            @include abstand($layout, margin, top, 27);
            @include schriftart($layout, 22, 24, 700);

            &--beschriftung {
                width: groesse($layout, 62);
            }

            &--buch {
                width: auto;
                font-weight: 300;
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .warenkorb-seite {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;
            width: 100%;
        }

        &__introtext {
            @include abstand($layout, padding, top, 54);
            @include schriftart($layout, 22, 28, 300);
        }

        &__warenkorb-container {
            @include abstand($layout, padding, alle, 84, 0, 109, 0);
        }

        &__warenkorb-titel {
            @include abstand($layout, margin, bottom, 51);
            @include schriftart($layout, 36, 40, 700);
        }

        &__buch-beschriftung-border {
            @include abstand($layout, padding, alle, 0, 0, 12, 0);
            @include schriftart($layout, 18, 18);
            border-bottom: 1px solid #fff;
        }

        &__buch-beschriftung {
            @include abstand($layout, margin, alle, 0, -8, 0, -8);
            display: flex;
        }

        &__buch {
            @include abstand($layout, padding, bottom, 9);
            @include schriftart($layout, 22, 60);
            border-bottom: 1px solid #fff;

        }

        &__buch-container {
            @include abstand($layout, margin, alle, 0, -8, 0, -8);
            display: flex;
        }

        &__buch-titel {
            @include abstand($layout, margin, alle, 0, 8, 0, 8);
            width: groesse($layout, 431);
            font-weight: 300;

            &--beschriftung {
                font-weight: 700;

                &--desktop {
                    display: block;
                }

                &--mobile {
                    display: none;
                }
            }
        }

        &__buch-kategorie {
            @include abstand($layout, margin, alle, 0, 8, 0, 8);
            width: groesse($layout, 139);
            font-weight: 300;

            &--beschriftung {
                @include abstand($layout, margin, alle, 0, 8, 0, 8);
                font-weight: 700;

                &--desktop {
                    display: block;
                }

                &--mobile {
                    display: none;
                }
            }
        }

        &__buch-anzahl {
            width: groesse($layout, 200);
            font-weight: 300;

            &--buch {
                @include abstand($layout, margin, alle, 13, 8, 0, 8);
            }

            &--beschriftung {
                @include abstand($layout, margin, alle, 0, 8, 0, 8);
                font-weight: 700;

                &--desktop {
                    display: block;
                }

                &--mobile {
                    display: none;
                }
            }
        }

        &__buch-input-container {
            height: groesse($layout, 39);
        }

        &__buch-input {
            @include abstand($layout, padding, alle, 0, 11, 0, 11);
            @include schriftart($layout, 22, 22);
            text-align: center;
            width: groesse($layout, 81);
        }

        &__buch-input-buttons {
            @include schriftart($layout, 22, 22);
            padding: 0;
            width: groesse($layout, 35);
        }

        &__buch-einzelpreis {
            @include abstand($layout, margin, alle, 0, 8, 0, 8);
            width: groesse($layout, 129);
            font-weight: 300;

            &--beschriftung {
                @include abstand($layout, margin, alle, 0, 8, 0, 8);
                font-weight: 700;

                &--desktop {
                    display: block;
                }

                &--mobile {
                    display: none;
                }
            }
        }

        &__buch-preis {
            @include abstand($layout, margin, alle, 0, 8, 0, 8);
            width: groesse($layout, 152);
            font-weight: 300;

            &--beschriftung {
                @include abstand($layout, margin, alle, 0, 8, 0, 8);
                font-weight: 700;

                &--desktop {
                    display: block;
                }

                &--mobile {
                    display: none;
                }
            }
        }

        &__buch-loeschen {
            @include abstand($layout, margin, alle, 0, 8, 0, 8);
            width: groesse($layout, 91);

            &--desktop {
                display: flex;
            }

            &--mobile {
                display: none;
            }
        }

        &__buch-loeschen-text {
            @include abstand($layout, margin, left, 6);
            @include schriftart($layout, 18, 60, 700);
        }

        &__buch-loeschen-svg {
            @include abstand($layout, margin, top, 24);
            width: groesse($layout, 14);
            height: groesse($layout, 14);
        }

        &__total-container {
            display: flex;
        }

        &__total {
            @include schriftart($layout, 22, 60, 700);

            &--beschriftung {
                width: groesse($layout, calc(1222 - 260));
            }

            &--buch {
                width: groesse($layout, 260);
            }
        }
    }

}