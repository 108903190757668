.login {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    min-height: 100vh;

    a {
        text-decoration: underline;
        color: #fff;
    }

    &__anmelden {
        appearance: none;
        background-color: white;
        border: none;
        color: black;
        cursor: pointer;
        text-decoration: none;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .login {
        @include abstand($layout, padding, top, 48);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__feld {
            @include abstand($layout, margin, top, 10);
            position: relative;
        }

        &__input {
            @include abstand($layout, padding, alle, 20, 10, 5, 10);
            @include schriftart($layout, 18, 14, 700);
            width: 100%;
            border: 0;
            background-color: white;
            border-bottom: 1px solid black;
            border-radius: 0;
            cursor: text;
            -webkit-appearance: none;

            &:focus {
                outline: 0;
                border-bottom: 1px solid black;
            }

            // Input nicht fokussiert
            &:placeholder-shown+label {
                cursor: text;
            }

            // Input fokussiert
            &:not(:placeholder-shown)+label,
            &:focus+label {
                @include schriftart($layout, 12, 14, 300);
                cursor: pointer;
                bottom: groesse($layout, 30);

            }

            &:not(:placeholder-shown):invalid {
                box-shadow: none;
                border-bottom-color: red;
            }

            &:not(:placeholder-shown):invalid+label {
                box-shadow: none;
                color: red;
            }

            &:-moz-submit-invalid {
                box-shadow: none;
            }

            &:-moz-ui-invalid {
                box-shadow: none;
            }
        }

        &__label {
            @include schriftart($layout, 18, 15, 300);
            width: 100%;
            bottom: groesse($layout, 25);
            left: groesse($layout, 10);
            position: absolute;
            transition: all 0.2s;
            touch-action: manipulation;
            z-index: 1;
            color: #000;
        }

        &__anmelden {
            @include abstand($layout, margin, top, 45);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 18, 18, 700);
            display: flex;
            align-items: center;
            height: groesse($layout, 30);
        }
        
        &__meldung {
            @include abstand($layout, margin, top, 10);
            @include schriftart($layout, 18, 23, 300);
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .login {
        @include abstand($layout, padding, top, 48);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
        }

        &__feld {
            @include abstand($layout, margin, top, 10);
            position: relative;
        }

        &__input {
            @include abstand($layout, padding, alle, 20, 10, 5, 10);
            @include schriftart($layout, 22, 28, 700);
            width: spalten($layout, 1, 2, 0, 0);
            border: 0;
            background-color: white;
            border-bottom: 1px solid black;
            border-radius: 0;
            cursor: text;
            -webkit-appearance: none;

            &:focus {
                outline: 0;
                border-bottom: 1px solid black;
            }

            // Input nicht fokussiert
            &:placeholder-shown+label {
                cursor: text;
            }

            // Input fokussiert
            &:not(:placeholder-shown)+label,
            &:focus+label {
                @include schriftart($layout, 14, 28, 300);
                cursor: pointer;
                bottom: groesse($layout, 30);
            }

            &:not(:placeholder-shown):invalid {
                box-shadow: none;
                border-bottom-color: red;
            }

            &:not(:placeholder-shown):invalid+label {
                box-shadow: none;
                color: red;
            }

            &:-moz-submit-invalid {
                box-shadow: none;
            }

            &:-moz-ui-invalid {
                box-shadow: none;
            }
        }

        &__label {
            @include schriftart($layout, 22, 28);
            width: 100%;
            bottom: groesse($layout, 25);
            left: groesse($layout, 10);
            position: absolute;
            transition: all 0.2s;
            touch-action: manipulation;
            z-index: 1;
            color: #000;
        }

        &__anmelden {
            @include abstand($layout, margin, top, 45);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 22, 700);
            align-items: center;
            display: flex;
            height: groesse($layout, 40);
        }
        
        &__meldung {
            @include abstand($layout, margin, top, 10);
            @include schriftart($layout, 22, 28, 300);
        }
    }
}