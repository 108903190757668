.verlag {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    min-height: 100vh;

    &__titel {
        width: 100%;
    }

    a {
        text-decoration: underline;
        color: #fff;
    }

    &__verlag-text {
        p {
            font-weight: 300;
        }

        ul {
            font-weight: 300;
        }

        strong {
            font-weight: 700;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .verlag {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;
        }

        &__introtext {
            @include abstand($layout, padding, alle, 22, 0, 22, 0);
            @include schriftart($layout, 22, 28, 700);
        }

        &__verlag-ueberschrift {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
            font-weight: 700;
        }

        &__verlag-texte {
            @include abstand($layout, padding, alle, 22, 0, 22, 0);
            @include schriftart($layout, 18, 23, 300);

            ul {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
                padding-left: groesse($layout, 20);
            }

            p {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .verlag {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;
        }

        &__introtext {
            @include abstand($layout, padding, alle, 48, 0, 48, 0);
            @include schriftart($layout, 36, 42, 700);
        }
        
        &__verlag {
            @include abstand($layout, margin, bottom, -48);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__verlag-ueberschrift {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
            font-weight: 700;
        }
        
        &__verlag-texte {
            @include abstand($layout, padding, top, 48);
            @include schriftart($layout, 22, 28);
            width: spalten($layout, 2, 4, 0, 0);

            p {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
            }

            ul {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                padding-left: groesse($layout, 23);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }
    }
}