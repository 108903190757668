.warenkorb {
    z-index: 10000;

    &__link {
        position: relative;
    }
    
    &__zahl {
        font-family: 'Neue Haas Unica W1G', sans-serif;
        align-items: center;
        background-color: black;
        border-radius: 1000px;
        color: white;
        display: flex;
        justify-content: center;
        position: absolute;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .warenkorb {
        display: block;
        position: absolute;
        left: groesse($layout, 245);
        top: groesse($layout, 51);

        .body--startseite & {
            display: none;
        }

        .body--startseite[data-menue-aktiv="true"] & {
            display: block;
        }

        &__svg {
            width: groesse($layout, 40);
            height: groesse($layout, 37);
        }

        &__zahl {
            @include schriftart($layout, 9, 27, 700);
            height: groesse($layout, 19);
            left: groesse($layout, -7);
            min-width: groesse($layout, 18);
            top: groesse($layout, 8);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .warenkorb {
        position: fixed;
        top: groesse($layout, 38);
        right: groesse($layout, 54);
        
        .body--shop &, .body--login &, .body--login-entry &, .body--benutzerprofil &, .body--reset-password &, .body--kontakt &, .body--verlag &, .body--warenkorb &, .body--datenschutz &, .body--impressum &, .body--agb & {
            top: groesse($layout, 38);
            right: groesse($layout, 25);
        }

        .body--startseite & {
            left: groesse($layout, 614);
        }

        &__svg {
            width: groesse($layout, 42);
            height: groesse($layout, 60);
        }

        &__zahl {
            @include schriftart($layout, 14, 36, 700);
            height: groesse($layout, 35);
            left: groesse($layout, -15);
            min-width: groesse($layout, 35);
            top: groesse($layout, 4);
        }
    }
}