.buch {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    font-weight: 300;

    &__kategorie {
        font-weight: 300;
    }

    &__beschreibung {
        font-weight: 700;
    }

    &__bild {
        position: relative;
    }

    &__buch-svg {
        position: absolute;
        z-index: 10;
    }

    &__buttons {

        &--2 {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__button {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        color: white;
        background-color: black;
        text-decoration: none;
        font-weight: 700;
        // Damit a & button die selbe hoehe haben
        display: inline-block;

        &--warenkorb {
            cursor: pointer;
            
            .body--word-entry & {
                background-color: #4676C1;
            }

            .body--excel-entry & {
                background-color: #0F9844;
            }

            .body--powerpoint-entry & {
                background-color: #EF5A18;
            }

            .body--junior-entry & {
                background-color: #7EF848;
                color: #000;
            }

            .body--shorty-entry & {
                color: #000;
                background-color: #E7EF20;
            }
        }

        &--download {
            color: black;
            font-weight: 700;

            .body--word-entry & {
                background-color: #4676C1;
            }

            .body--excel-entry & {
                background-color: #0F9844;
            }

            .body--powerpoint-entry & {
                background-color: #EF5A18;
            }

            .body--junior-entry & {
                background-color: #7EF848;
            }

            .body--shorty-entry & {
                background-color: #F5FF00;
            }
        }
    }

    &__kurzbeschreibung {

        .body--word-entry & {
            color: #4676C1;
        }

        .body--excel-entry & {
            color: #0F9844;
        }

        .body--powerpoint-entry & {
            color: #EF5A18;
        }

        .body--junior-entry & {
            color: #7EF848;
        }

        .body--shorty-entry & {
            color: #E7EF20;
        }
    }

    &__weitere-lehrmittel {
        display: flex;
        flex-direction: column;
    }

    &__weitere-lehrmittel-titel {
        font-weight: 700;
    }

    &__weitere-lehrmittel-titel {
        text-decoration: underline;
        color: #000;
    }

    &__details {
        
        strong {
            font-weight: 700;
        }
    }

    &__shorty-cover {
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
    }
    
    &__shorty-cover-container {
        background-color: #F5FF00;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: rotate(340deg);
    }
    
    &__shorty-cover-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: black;
            width: 100%;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .buch {

        &__einleitung {
            
            &--desktop {
                display: none;
            }
            
            &--smartphone {
                display: block;
            }
        }

        &__titel {
            @include schriftart($layout, 22, 27);
        }
        
        &__kurzbeschreibung {
            @include schriftart($layout, 22, 27);
        }

        &__bild {
            @include abstand($layout, margin, bottom, 22 - 4);
        }

        &__buch-svg {
            width: groesse($layout, 90);
            height: groesse($layout, 90);
            transform: rotate(-20deg);
            top: groesse($layout, 25);
            right: groesse($layout, 25);
        }

        &__buttons {

            &--1-desktop {
                display: none;
            }

            &--1-smartphone {
                display: block;
            }
        }

        &__button {
            @include abstand($layout, margin, alle, 4, 4, 4, 4);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 18, 23);
            height: groesse($layout, 30);
            display: inline-flex;
            align-items: center;
        }
        
        &__kategorie {
            @include abstand($layout, padding, bottom, 12);
            @include abstand($layout, margin, bottom, 31);
            @include schriftart($layout, 22, 27);
        }

        &__beschreibung {
            @include abstand($layout, margin, alle, 58 - 4, 0, 42, 0);
            @include schriftart($layout, 18, 23);
        }

        &__details {
            @include schriftart($layout, 15, 20);

            p {
                @include abstand($layout, margin, alle, 35, 0, 0, 0);
            }

            ul {
                @include abstand($layout, margin, alle, 0, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
                padding-left: groesse($layout, 20);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }

        &__weitere-lehrmittel {
            @include abstand($layout, margin, top, 24);
            @include schriftart($layout, 15, 20);
        }

        &__kategorie {
            border-bottom: groesse($layout, 2) solid black;
        }

        &__shorty-cover-text {
            @include schriftart($layout, 63, 90, bold);
            
            &::after {
                height: groesse($layout, 9);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .buch {
        @include abstand($layout, margin, top, 129);
        display: flex;
        justify-content: space-between;

        &__einleitung {

            &--desktop {
                display: block;
            }
    
            &--smartphone {
                display: none;
            }
        }

        &__container {

            &--1 {
                width: groesse($layout, 500);
            }

            &--2 {
                width: spalten($layout, 2, 4, 0, 0.4);
            }
        }

        &__sticky-container {
            position: sticky;
            top: groesse($layout, 129);
        }

        &__bild {
            @include abstand($layout, margin, bottom, 40);

            .buch--shorty & {
                padding-left: 50%;
            }
        }

        &__buch-svg {
            width: groesse($layout, 135);
            height: groesse($layout, 135);
            transform: rotate(-20deg);
            right: groesse($layout, 40);
            top: groesse($layout, 40);
        }

        &__buttons {

            &--1-desktop {
                display: flex;
                justify-content: space-between;
            }
            
            &--1-smartphone {
                display: none;
            }
        }

        &__button {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 18, 28);
            display: flex;
            align-items: center;
            height: groesse($layout, 40);
        }

        &__titel {
            @include schriftart($layout, 36, 42, 700);
        }

        &__kurzbeschreibung {
            @include schriftart($layout, 36, 42, 700);
        }

        &__kategorie {
            @include abstand($layout, padding, bottom, 18);
            @include abstand($layout, margin, bottom, 29);
            @include schriftart($layout, 36, 42);
            border-bottom: groesse($layout, 3) solid black;
        }

        &__beschreibung {
            @include abstand($layout, margin, bottom, 18);
            @include schriftart($layout, 22, 27);
        }

        &__details {
            @include schriftart($layout, 18, 23);

            p {
                @include abstand($layout, margin, alle, 35, 0, 0, 0);
            }

            ul {
                @include abstand($layout, margin, alle, 0, 0, 35, 0);

                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                padding-left: groesse($layout, 23);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }
        
        &__weitere-lehrmittel {
            @include abstand($layout, margin, top, 30);
            @include schriftart($layout, 18, 23);
        }

        &__shorty-cover-text {
            @include schriftart($layout, 44, 64, bold);
            
            &::after {
                height: groesse($layout, 6);
            }
        }
    }
}