.header {

}


@include mediaquery(smartphone) {

    $layout: smartphone;
    
    .header {
        .body[data-menue-aktiv="true"] & {
            background-color: white;
            height: 100%;
            left: 0;
            position: fixed;
            overflow-y: scroll;
            overflow-x: hidden;
            top: 0;
            width: 100vw;
            z-index: 1000;
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .header {
        
    }

}