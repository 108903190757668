.kontakt {
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    font-family: 'Neue Haas Unica W1G', sans-serif;
    font-style: normal;
    min-height: 100vh;

    &__kontakt {
        font-style: normal;
    }

    &__email {
        color: white;
        text-decoration: underline;
        font-weight: 700;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontakt {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;
        }

        &__kontakt {
            @include abstand($layout, padding, top, 20);
            @include schriftart($layout, 22, 28, 700);
        }

        &__text {
            @include schriftart($layout, 18, 23, 300);
            @include abstand($layout, margin, alle, 40, 0, 10, 0);
        }
        
        &__email {
            @include schriftart($layout, 22, 28, 700);
        }
        
    }

}


@include mediaquery(desktop) {

    $layout: desktop;
    
    .kontakt {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
        }
        
        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;
            width: 100%;
        }

        &__kontakt {
            @include abstand($layout, padding, top, 54);
            @include schriftart($layout, 36, 42, 700);
        }
        
        &__text {
            @include schriftart($layout, 22, 28, 300);
            @include abstand($layout, margin, alle, 40, 0, 10, 0);
            width: spalten($layout, 2, 4, 0, 0);
        }
        
        &__email {
            @include schriftart($layout, 36, 42, 700);
        }
    }
}