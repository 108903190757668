.menue {

    &__menue-link {
        font-weight: 300;
        font-family: 'Neue Haas Unica W1G', sans-serif;

        &--buch {
            font-weight: 800;
            text-decoration: none;
        }

        &--regular {
            font-weight: 300;
            text-decoration: none;
        }
    }

    &__menue-impressum {
        font-family: 'Neue Haas Unica W1G', sans-serif;
    }

    &__menue-impressum-link {
        font-weight: 300;
        color: black;
        text-decoration: none;

        &:hover,
        &[data-link-aktiv="true"] {
            font-weight: 700;
        }
    }

    &__menue-link-span[data-link-aktiv="true"][data-menue-link="1"],
    &__menue-link-span[data-menue-link="1"]:hover {
        color: white;
    }

    &__menue-link-span[data-link-aktiv="true"][data-menue-link="2"],
    &__menue-link-span[data-menue-link="2"]:hover {
        background-color: black;
    }

    &__menue-link-span--regular[data-link-aktiv="true"][data-menue-link="2"],
    &__menue-link-span--regular[data-menue-link="2"]:hover {
        color: white;
    }


    &__menue-link-span {

        &--word[data-link-aktiv="true"],
        &--word:hover {
            background-color: #4676C1;
        }

        &--excel[data-link-aktiv="true"],
        &--excel:hover {
            background-color: #0F9844;
        }

        &--powerpoint[data-link-aktiv="true"],
        &--powerpoint:hover {
            background-color: #EF5A18;
        }

        &--junior[data-link-aktiv="true"],
        &--junior:hover {
            color: white;
        }

        &--shorty[data-link-aktiv="true"],
        &--shorty:hover {
            color: #F5FF00;
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .menue {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;

        .body[data-menue-aktiv="true"] & {
            height: calc(var(--vh, 1vh) * 100);
            width: 100vw;
        }

        &__menue {
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.5s;
            width: 100%;
            z-index: 90;

            .body[data-menue-aktiv="true"] & {
                height: calc(var(--vh, 1vh) * 100);
            }
        }

        &__menue-logo {

            &--text {
                display: none;
                position: absolute;
                height: groesse($layout, 67);
                left: groesse($layout, 86);
                top: groesse($layout, 49);
                width: groesse($layout, 191);

                .body--startseite[data-menue-aktiv="false"] & {
                    display: block;
                }
            }

            &--normal {
                display: block;
                position: absolute;
                height: groesse($layout, 31.5);
                left: groesse($layout, 30);
                top: groesse($layout, 49);
                width: groesse($layout, 140);

                .body--startseite[data-menue-aktiv="false"] & {
                    display: none;
                }
            }
        }

        &__menue-titel {

            &--1 {
                display: none;
                position: absolute;
                height: groesse($layout, 175);
                left: groesse($layout, 30);
                top: groesse($layout, 49);
                width: groesse($layout, 54);

                .body--startseite[data-menue-aktiv="false"] & {
                    display: block;
                }
            }

            &--2 {
                @include abstand($layout, margin, alle, 140, 0, 0, 30);
                height: groesse($layout, 403);
                max-height: 41.6vh;
                width: groesse($layout, 85);
            }
        }

        &__menue-container {
            display: none;

            .body[data-menue-aktiv="true"] & {
                display: flex;
                flex-wrap: wrap;
            }

            &--2 {
                @include abstand($layout, margin, top, 25);
                margin-left: spalten($layout, 0, 2, 0, 2);
            }
        }

        &__menue-container-box {
            @include abstand($layout, margin, top, 133);
        }

        &__menue-links {
            display: flex;
            flex-direction: column;

            &--1,
            &--2 {
                @include abstand($layout, margin, alle, 0, -10, 20, -10);
            }
        }

        &__menue-link {
            @include abstand($layout, margin, top, 2);

            &:first-child {
                @include abstand($layout, margin, top, -5);
            }

            // 90 von 41
            font-size: groesse($layout, 36);
            color: #000;

            &--regular {
                font-size: groesse($layout, 23);
            }
        }

        &__menue-link-span {
            display: inline-flex;
            align-items: center;

            &--buch {
                height: groesse($layout, 55);
                @include abstand($layout, padding, alle, 0, 10, 0, 10);
            }

            &--regular {
                height: groesse($layout, 35);
                @include abstand($layout, padding, alle, 0, 8, 0, 8);
            }
        }

        &__menue-impressum {
            @include abstand($layout, margin, alle, 15, -10, 0, 30 - 10);
            display: none;

            .body[data-menue-aktiv="true"] & {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__menue-impressum-link {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            @include schriftart($layout, 15, 28);
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 43);
            $hamburger-hoehe: groesse($layout, 32);
            $hamburger-strichstaerke: groesse($layout, 6);
            $hamburger-farbe: black;
            $hamburger-farbe-aktiv: black;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, padding, alle, 20, 20, 20, 20);
            margin-right: groesse($layout, 10);
            position: absolute;
            right: 0;
            top: groesse($layout, 35.5);
            z-index: 120;
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .menue {
        height: 100%;
        height: 100%;
        position: absolute;
        left: groesse($layout, 140);
        z-index: 130;
        top: 0;

        &__menue {
            transition: .5s;
            position: sticky;
            top: groesse($layout, 41);
            margin-top: groesse($layout, 41);
        }

        &__menue-logo--normal {
            display: none;
        }

        &__menue-logo--text {
            // 90% von 52
            @include abstand($layout, margin, bottom, 46.8);

            // 90% von 225
            width: groesse($layout, 202.5);
            // 90% von 79
            height: groesse($layout, 71.1);
        }


        &__menue-container {
            display: flex;
            flex-wrap: wrap;

            &--2 {
                // 90% von 25
                @include abstand($layout, margin, top, 22.5);
                flex-direction: column;
            }
        }

        &__menue-titel {

            &--1 {
                display: none;
            }

            &--2 {
                // 90% von 12, 33, 1
                @include abstand($layout, margin, alle, 10.8, 29.7, 0.9, 0);
                // 90% von 87.36
                width: groesse($layout, 78.624);
                // 90% von 564.48
                height: groesse($layout, 508.032);
                // 90% von 53.981vh
                max-height: 48.5829vh;
                // Der Titel soll mindestens so hoch sein, wie die Menüpunkte zusammen hoch sind.
                // Weil die Schrift des Menüs in vw ist, ist die min-height auch ich in vw
                // 90% von 14.7vw
                min-height: 13.23vw
            }
        }

        &__menue-links {
            // 90% von 10
            @include abstand($layout, margin, bottom, 25);
            display: flex;
            flex-direction: column;
            align-items: flex-start;


            &--1 {
                .body--startseite & {
                    // 90% von -6.5, 30
                    @include abstand($layout, margin, alle, -5.85, 0, 30, 0);
                }
            }

            &--2 {
                .body--startseite & {
                    //90% von -6.5
                    @include abstand($layout, margin, top, -5.85);
                }
            }
        }

        &__menue-link {
            color: black;
            display: inline-flex;
            position: relative;

            // 90% von 6 -2, -12, 6 - 8, -12
            @include abstand($layout, margin, alle, 5.4 - 1.8, -10.8, 5.4 - 7.2, -10.8);
            // 90% von 36
            font-size: groesse($layout, 32.4);

            &--buch {

                .body--startseite & {
                    // 90% von 6 -2, -12, 6 - 8, -12
                    @include abstand($layout, margin, alle, 0, -10.8, 5.4 - 7.2, -10.8);
                    font-size: groesse($layout, 58.5);
                }
            }

        }

        &__menue-link-span {
            display: flex;
            align-items: center;

            // 90% von 0, 12, 0, 12
            @include abstand($layout, padding, alle, 0, 10.8, 0, 10.8);
            height: groesse($layout, 40);

            &--buch {

                .body--startseite & {
                    // 90% von 0, 12, 0, 12
                    @include abstand($layout, padding, alle, 0, 10.8, 0, 10.8);
                    height: groesse($layout, 75);
                }
            }

        }

        &__menue-impressum {
            // 90% von 50, -10, 0, -10
            @include abstand($layout, margin, alle, 30, -9, 2, -9);
        }

        &__menue-impressum-link {
            // 90% von 10
            @include abstand($layout, margin, alle, 0, 9, 2, 9);
            // 90% von 15, 28
            @include schriftart($layout, 13.5, 25.2);
        }

        &__hamburger {
            display: none;
        }
    }
}