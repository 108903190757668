.fui-i {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    
    a {
        color: #fff;   
    }

    a {
        color: #fff;
    }

    .fui-radio-label,
    .fui-label,
    .fui-checkbox-label {
        color: #fff;
    }

    .fui-input[name="fields[institution]"],
    .fui-input[name="fields[vorname][firstName]"],
    .fui-input[name="fields[nachname]"],
    .fui-input[name="fields[adresse][address1]"],
    .fui-input[name="fields[plz]"],
    .fui-input[name="fields[ort]"],
    .fui-input[name="fields[telefonnummer][number]"],
    .fui-input[name="fields[eMail]"] {
        font-weight: 700;
        border-radius: 0;
    }

    .fui-submit {
        appearance: none;
        background-color: white;
        border: none;
        color: black;
        cursor: pointer;
        text-decoration: none;
        border-radius: 0;
    }

    .fui-checkbox label::before, .fui-radio label::before {
        background-color: #fff;
        border: 4px solid #fff;
    }

    .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before {
        background-color: black;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .fui-i {

        .fui-layout-horizontal .fui-layout-wrap {
            @include abstand($layout, margin, bottom, 23);
            display: flex;
            flex-direction: column;
        }

        .fui-field:not(.fui-type-hidden-field) {
            margin-bottom: groesse($layout, 10);
        }

        .fui-radio-label,
        .fui-label,
        .fui-checkbox-label {
            font-size: groesse($layout, 18);
        }

        .fui-field-container {
            position: relative;
        }


        .form-floating > .form-floating-control::placeholder {
            color: transparent;
        }

        .fui-input {
            @include abstand($layout, padding, alle, 20, 10, 5, 10);
            @include schriftart($layout, 18, 14, 700);
            width: 100%;
            border: 0;
            background-color: white;
            border-bottom: 1px solid #000;
            border-radius: 0;
            cursor: text;
            -webkit-appearance: none;
            color: #000;
        }

        .form-floating {
            position: relative;
        }
        
        .form-floating > label {
            @include schriftart($layout, 18, 14);
            width: 100%;
            bottom: groesse($layout, 25);
            left: groesse($layout, 10);
            position: absolute;
            transition: all 0.2s;
            touch-action: manipulation;
            z-index: 1;
            color: #000;
            pointer-events: none;
            transform-origin: 0 0;
            transition: opacity .1s ease-in-out,transform .1s ease-in-out;
        }

        .form-floating > .form-floating-control:focus ~ label,
        .form-floating > .form-floating-control:not(:placeholder-shown) ~ label {
            opacity: 1;
            transform: scale(0.63);
            color: #000;
        }
        
        .form-floating > .form-floating-control:-webkit-autofill ~ label {
            opacity: 1;
            transform: scale(0.63);
            color: #000;
        }

        
        .fui-label {
            @include schriftart($layout, 18, 14);
            width: 100%;
            bottom: groesse($layout, 25);
            left: groesse($layout, 10);
            position: absolute;
            transition: all 0.2s;
            touch-action: manipulation;
            z-index: 1;
            color: #000;
            font-weight: 300;
        }

        .fui-type-agree  {
            @include abstand($layout, padding, top, 30);
        }

        .fui-btn-wrapper {
            @include abstand($layout, padding, top, 35);
        }

        .fui-submit {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 18, 18, 700);
            display: flex;
            align-items: center;
            height: groesse($layout, 30);
        }

        .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before {
            background-color: black;
            border: groesse($layout, 2) solid white;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .fui-i {

        .fui-field:not(.fui-type-hidden-field) {
            margin-bottom: groesse($layout, 10);
        }

        .fui-radio-label,
        .fui-label,
        .fui-checkbox-label {
            font-size: groesse($layout, 22);
        }

        .fui-field-container {
            position: relative;
        }


        .form-floating > .form-floating-control::placeholder {
            color: transparent;
        }

        .fui-input {
            @include abstand($layout, padding, alle, 20, 10, 5, 10);
            @include schriftart($layout, 22, 28, 700);
            width: spalten($layout, 1, 2, -1, 0);
            border: 0;
            background-color: white;
            border-bottom: 1px solid #000;
            border-radius: 0;
            cursor: text;
            -webkit-appearance: none;
            color: #000;
        }

        .form-floating {
            position: relative;
        }
        
        .form-floating > label {
            @include schriftart($layout, 22, 28);
            width: 100%;
            bottom: groesse($layout, 25);
            left: groesse($layout, 10);
            position: absolute;
            transition: all 0.2s;
            touch-action: manipulation;
            z-index: 1;
            color: #000;
            pointer-events: none;
            transform-origin: 0 0;
            transition: opacity .1s ease-in-out,transform .1s ease-in-out;
        }

        .form-floating > .form-floating-control:focus ~ label,
        .form-floating > .form-floating-control:not(:placeholder-shown) ~ label {
            opacity: 1;
            transform: scale(0.63);
            color: #000;
        }
        
        .form-floating > .form-floating-control:-webkit-autofill ~ label {
            opacity: 1;
            transform: scale(0.63);
            color: #000;
        }

        .fui-type-agree  {
            @include abstand($layout, padding, top, 30);
        }

        .fui-btn-wrapper {
            @include abstand($layout, padding, top, 35);
        }
        
        .fui-label {
            @include schriftart($layout, 22, 28);
            width: 100%;
            bottom: groesse($layout, 25);
            left: groesse($layout, 10);
            position: absolute;
            transition: all 0.2s;
            touch-action: manipulation;
            z-index: 1;
            color: #000;
            font-weight: 300;
        }

        .fui-submit {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 28, 700);   
            display: flex;
            align-items: center;
            height: groesse($layout, 40);         

            &:hover {
                background-color: white;
                border: none;
                color: black;
            }
        }

        .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before {
            background-color: black;
            border: groesse($layout, 3) solid white;
        }
    }
}