.benutzerprofil {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    min-height: 100vh;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .benutzerprofil {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;

            &--desktop {
                display: none;
            }

            &--smartphone {
                display: block;
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .benutzerprofil {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;
            width: 100%;
        }

        &__profil {
            @include abstand($layout, padding, top, 48);
            width: 100%;
        }
    }

}