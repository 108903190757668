.agb {
    font-family: 'Neue Haas Unica W1G', sans-serif;
    background-color: map-get($farben, hintergrundDunkel);
    color: white;
    min-height: 100vh;
    
    
    &__agb {
        
        p {
            font-weight: 300;
        }
        
        ul {
            font-weight: 300;
        }
        
        strong {
            font-weight: 700;
        }

        a {
            color: white;
        }
    }

    &__download {
        appearance: none;
        background-color: white;
        border: none;
        color: black;
        cursor: pointer;
        text-decoration: none;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .agb {
        @include abstand($layout, margin, alle, 0, 0, -183 - 22, 0);

        &__container {
            @include abstand($layout, padding, alle, 22, 30, 183, 30);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 10);
            @include schriftart($layout, 36, 40, 300);
            border-bottom: groesse($layout, 2) solid white;

            &--desktop {
                display: none;
            }

            &--smartphone {
                display: block;
            }
        }

        &__agb {
            @include abstand($layout, padding, alle, 22, 0, 22, 0);
            @include schriftart($layout, 18, 23, 300);

            ul {
                li {
                    @include abstand($layout, padding, left, 13);
                }
            }

            ol {
                padding-left: groesse($layout, 20);
            }

            p {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }

        &__download {
            @include abstand($layout, margin, top, 65);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 18, 18, 700);
            align-items: center;
            display: inline-flex;
            height: groesse($layout, 30);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .agb {
        @include abstand($layout, margin, alle, -38, -50, -145, -50);

        &__container {
            @include abstand($layout, padding, alle, 45, 50, 145 - 45, 50);
        }

        &__titel {
            @include abstand($layout, padding, bottom, 26);
            @include schriftart($layout, 60, 40, 300);
            border-bottom: groesse($layout, 3) solid white;

            &--desktop {
                display: block;
            }

            &--smartphone {
                display: none;
            }
        }

        &__agb {
            @include abstand($layout, padding, top, 48);
            @include schriftart($layout, 22, 28);
            width: spalten($layout, 2, 4, 0, 0);

            ul {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
                
                li {
                    @include abstand($layout, padding, left, 13);
                }
            }
            
            ol {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
                padding-left: groesse($layout, 23);
            }

            p {
                @include abstand($layout, margin, alle, 35, 0, 35, 0);
            }

            *:first-child {
                @include abstand($layout, margin, top, 0);
            }

            *:last-child {
                @include abstand($layout, margin, bottom, 0);
            }
        }

        &__download {
            @include abstand($layout, margin, top, 65);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include schriftart($layout, 22, 22, 700);
            height: groesse($layout, 40);
            display: inline-flex;
            align-items: center;
        }
    }
}